
















import {Component, Prop} from 'vue-property-decorator'
import Base from "@/data/model/Base"
import {ApplicationUser, Role} from "@/data/model/ApplicationUser"

@Component({})
export default class UserChooser extends Base {
  @Prop({required: false, default: []})
  selectedUsers!: Array<ApplicationUser>
  cUser = new Array<ApplicationUser>()

  constructor() {
    super('user-chooser')
  }

  created(){
    this.$store.dispatch('getAllUsers').then((users: Array<ApplicationUser>) => {
      this.cUser = users.filter(u => u.role !== Role.ADMIN)
    })
  }

  get users(){
    return this.cUser
  }
}
