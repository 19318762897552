import {IntervalBuilder} from "@/data/builder/IntervalBuilder";
import {Address} from "@/data/model/Address";
import {Project, Status} from "@/data/model/Project";
import {AddressBuilder} from "@/data/builder/AddressBuilder";
import {OrderElement} from "@/data/model/OrderElement";

export class ProjectBuilder {
    private readonly project: Project

    constructor() {
        this.project = {
            id: '',
            address: [new AddressBuilder().build()],
            name: '',
            startDate: '',
            deliveryDate: '',
            estimatedDeliveryDate:'',
            order: [],
            comment: '',
            comments: [],
            type: '',
            status: Status.RECEIVED,
            noteSignal: 'white'
        }
    }

    id(id: string): ProjectBuilder {
        this.project.id = id
        return this
    }

    address(address: Array<Address>): ProjectBuilder {
        this.project.address = address
        return this
    }

    name(name: string): ProjectBuilder {
        this.project.name = name
        return this
    }

    order(order: Array<OrderElement>): ProjectBuilder {
        this.project.order = order
        return this
    }

    status(status: string): ProjectBuilder {
        this.project.comment = status
        return this
    }

    type(type: string): ProjectBuilder {
        this.project.type = type
        return this
    }

    build(): Project {
        return this.project
    }
}
