

































import {Component, Prop} from "vue-property-decorator";
import Base, {Form} from "@/data/model/Base";

@Component({})
export default class DatePicker extends Base {
  @Prop({required: true})
  public date!: any

  @Prop({required: true})
  public labelString!: String

  isClickedOnMenu = false
  localDate = ""

  constructor() {
    super('datepicker')
  }

  mounted(){
    this.localDate = this.date
  }

  get getDate(){
    return this.date
  }

  onChangeLocalDate(newVal: any){
    this.$emit('onchange', newVal)
    this.$emit('change', newVal)
  }
}
