



























































































































































































































import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import {CommentType, Project, Status} from "@/data/model/Project";
import {OrderElement, OrderPosition, OrderTypes} from "@/data/model/OrderElement";
import AddressForm from "@/components/AddressForm.vue";
import CommentsView from "@/components/CommentsView.vue";
import UserChooser from "@/components/UserChooser.vue";
import {ApplicationUser} from "@/data/model/ApplicationUser";
import Base, {Form} from "@/data/model/Base";
import {ProjectBuilder} from "@/data/builder/ProjectBuilder";
import DatePicker from "@/components/DatePicker.vue";

@Component({
  components: {DatePicker, UserChooser, CommentsView, AddressForm},
})

export default class ProjectForm extends Base {
  public isFormValid = false
  public isStartDate = false
  public isEstimatedDeliveryDate = false
  public isDeliveryDate = false
  public isSaving = false
  public showInfoTextForAutoComment = false

  public selectedOrders: Array<{ value: string, text: any }> = []
  // { "value": "warning", "text": "project-form.status.warning" }
  public selectedStatus: { value: any, text: any } = {"value": '', "text": ''}
  public selectedUsers = new Array<ApplicationUser>()

  constructor() {
    super('project-form')
  }

  @Prop({required: true})
  public value!: Project

  @Prop({required: true, default: true})
  public isUpdatingProject!: boolean

  @Ref('projectFrom')
  projectFrom!: Form

  //rule set
  get notEmptyRule() {
    return [
      (v: string) => !!v || this.getLangTextWithoutPrefix('form.notEmpty'),
    ]
  }

  beforeUpdate() {
    if (this.value.order && this.value.order.length === 0) {
      this.value.order.forEach(o => {
        if (o.name === OrderTypes.FIREPROTECTION
            || o.name === OrderTypes.SOUNDPROOFING
            || o.name === OrderTypes.ENEV
            || o.name === OrderTypes.GEG_EVIDENCE
            || o.name === OrderTypes.CONSTRUCTION_SUPERVISION
        ) {
          if (!o.assigned) {
            o.assigned = {
              isAssigned: false,
              date: ""
            }
          }
          if (!o.delivered) {
            o.delivered = {
              isDelivered: false,
              date: ""
            }
          }
          console.log('order ', o)
        }
      })
    }
  }

  beforeMount() {

    if (!this.value.comments) {
      this.value.comments = []
    }
    if (this.isUpdatingProject) {
      // pre order when exists (update)
      this.selectedStatus = {
        "value": this.value.status,
        "text": this.getLangTextWithoutPrefixAndWithoutHTML('status.' + this.value.status)
      }

      if (this.value.order?.length != 0) {
        // Sorting Costum list
        this.value.order?.sort((a, b) => OrderPosition[a.name as keyof typeof OrderPosition] - OrderPosition[b.name as keyof typeof OrderPosition])
        this.value.order?.forEach(el =>
            this.selectedOrders.push({
              value: el.name,
              text: this.getLangTextWithoutPrefix('orderTypes.' + el.name)
            })
        )
      }

      this.$store.dispatch('getAllUsers').then((users: Array<ApplicationUser>) => {
        if (this.value.id) {
          this.selectedUsers = users.filter(u => u.customer?.projectsNr.includes(this.fomartID(this.value.id)))
        }
      })

      this.selectedOrders.sort((a: any, b: any) => OrderPosition[a.value as keyof typeof OrderPosition] - OrderPosition[b.value as keyof typeof OrderPosition])
    } else {
      // this.projectFrom.reset()
      this.value = new ProjectBuilder().build()
    }
  }

  get status() {
    let statusArray: any = []
    Object.keys(Status).slice(0, -1).forEach((key: string) =>
        statusArray.push({value: key, text: this.getLangTextWithoutPrefixAndWithoutHTML('status.' + key)})
    )
    return statusArray
  }

  // getAll Ordertypes with right lang
  get orderTypes() {
    const oStrings: any = []
    const o = Object.keys(OrderTypes).filter((item) => {
      return isNaN(Number(item));
    }).forEach(ordertype => {
      oStrings.push({
        text: this.getLangTextWithoutPrefix('orderTypes.' + ordertype),
        value: ordertype
      })
    });

    oStrings.sort((a: any, b: any) => OrderPosition[a.value as keyof typeof OrderPosition] - OrderPosition[b.value as keyof typeof OrderPosition])
    return oStrings
  }

  get orderTypesEnum() {
    return OrderTypes
  }

  //add order when selected
  public addOrder(oderList: [{ value: any }]) {
    //remove
    if(oderList.length < this.value.order!.length) {
      let cleardOrders: OrderElement[] = []
      oderList.forEach(ol => {
        let order = this.value.order?.find(o => o.name === ol.value)
        if(order){
          cleardOrders.push(order)
        }
      })
      this.value.order = cleardOrders
    } else {
      // put all new objects in order
      oderList.forEach(e => {
        if(!this.value.order?.find(o => o.name === e.value)){
          this.value.order?.push({
            name: e.value,
            isOrder: false,
            responsible: '',
            responsibleStartDate: '',
            assigned: {isAssigned: false, date: ""},
            delivered: {isDelivered: false, date: ""}
          })
        }

        // preselect autmatik PROBATIO_PLANS when STATIC was set
        if (oderList.find(c => c.value.toString() === 'STATIC') && !oderList.find(c => c.value.toString() === 'PROBATIO_PLANS')) {
          this.selectedOrders.push({
            value: 'PROBATIO_PLANS',
            text: this.getLangTextWithoutPrefix('orderTypes.PROBATIO_PLANS')
          })
          if(!this.value.order?.find(o => o.name === 'PROBATIO_PLANS')) {
            this.value.order?.push({
              name: 'PROBATIO_PLANS',
              isOrder: false,
              responsible: '',
              responsibleStartDate: '',
              assigned: {isAssigned: false, date: ""},
              delivered: {isDelivered: false, date: ""}
            })
          }
        }
        // preselect autmatik PROBATIO_PLANS when STATIC was set
        if (oderList.find(c => c.value.toString() === OrderTypes.GEG_EVIDENCE) && !oderList.find(c => c.value.toString() === OrderTypes.ENEV)) {
          this.selectedOrders.push({
            value: 'ENEV',
            text: this.getLangTextWithoutPrefix('orderTypes.ENEV')
          })
          if(!this.value.order?.find(o => o.name === 'ENEV')) {
            this.value.order?.push({
              name: 'ENEV',
              isOrder: false,
              responsible: '',
              responsibleStartDate: '',
              assigned: {isAssigned: false, date: ""},
              delivered: {isDelivered: false, date: ""}
            })
          }
        }
      })
      // sorte by a special list
      this.value.order?.sort((a, b) => OrderPosition[a.name as keyof typeof OrderPosition] - OrderPosition[b.name as keyof typeof OrderPosition])
    }
  }

  // Form frunktions
  public validateAndSend() {
    if (this.projectFrom.validate()) {
      this.send()
    }
  }

  setSelectedUser(users: Array<ApplicationUser>) {
    this.selectedUsers = users
  }

  private send() {
    this.isSaving = true

    if (this.selectedStatus.value != 'COMPLETED') {
      this.value.deliveryDate = ''
    }

    // add comment when it is a warning
    if (this.value.status === Status.BLOCKED || this.value.status === Status.PREPARED_AND_INCOMPLETE) {
      this.addWarningComment()
    }
    if (this.isUpdatingProject) {
      this.$store.dispatch('updateProject', this.value).then((projectId: string) => {
        if (this.selectedUsers.length != 0) {
          this.selectedUsers.forEach(u => {
            if (!u.customer?.projectsNr.includes(this.fomartID(projectId))) {
              u.customer?.projectsNr.push(projectId)
              this.$store.dispatch('updateUser', u).then(() => {
                this.cancel()
              }).catch(() => {
                this.cancel()
              })
            }
            this.cancel()
          })
        } else {
          this.cancel()
        }

      }).catch(() => {
        this.isSaving = false
      })
    } else {

      this.$store.dispatch('createProject', this.value).then((projectId: string) => {
        if (this.selectedUsers.length > 0) {
          this.selectedUsers.forEach(u => {
            u.customer?.projectsNr.push(projectId)
            this.$store.dispatch('updateUser', u).then(() => {
              this.cancel()
            }).catch(() => {
              this.cancel()
            })
          })
        } else {
          this.cancel()
        }
      }).catch(() => {
        this.isSaving = false
      })
    }
  }

  private addWarningComment() {
    console.log(this.value.comments)
    const warningAlreadyExists: boolean | undefined = this.value.comments?.some(comment => comment.text === this.getLangTextWithoutPrefix('project-overview.warning'))
    if (!warningAlreadyExists) {
      this.value.comments?.push({
        author: "Admin",
        creationDate: new Date(Date.now()).toISOString(),
        text: this.getLangText('warning'),
        type: CommentType.WARING,
        isExtern: true
      })
    }
  }

  public cancel() {
    (this.$refs.projectFrom as Vue & { reset: () => boolean }).reset()
    this.selectedOrders = []
    this.isEstimatedDeliveryDate = false
    this.isDeliveryDate = false
    this.isStartDate = false
    this.isSaving = false
    this.$emit('clicked')
    // this.$refs.projectFrom.reset()
  }

  @Watch('selectedStatus', {deep: true})
  onSelectedStatus(newVal: any, oldVal: any) {
    this.value.status = (newVal.value as Status)
    this.showInfoTextForAutoComment = false
    if (this.value.status === Status.BLOCKED || this.value.status === Status.PREPARED_AND_INCOMPLETE) {
      this.showInfoTextForAutoComment = true
    }
  }

}
