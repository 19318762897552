


















































import {Component} from "vue-property-decorator";
import {ProjectBuilder} from "@/data/builder/ProjectBuilder";
import {Project, Status} from "@/data/model/Project";
import moment from 'moment'
import ProjectForm from "@/components/ProjectForm.vue";
import CommentsView from "@/components/CommentsView.vue";
import ProjectTable from "@/components/ProjectTable.vue";
import Base from "@/data/model/Base";

@Component({
  components: {
    ProjectTable,
    CommentsView,
    ProjectForm,
    addressFrom: () => import('../components/AddressForm.vue')
  },
})

export default class ManageProjects extends Base {
  //control
  private selectedProjectDetails = {}
  private createOrUpdate = false
  private isUpdatingProject = false
  private isCreatingProject = false
  private formValid = false
  private reallyDeleteProject = false
  private reallyDeleteValue = ''
  private isAllProjectLoaded= false
  //data
  private listOfProjects: Array<Project> = []
  private createOrUpdateProject: Project = new ProjectBuilder().build()
  private menus = {start: false, end: false}

  constructor() {
    super('manage-projects')
  }


  created() {
    this.refreshProjects()
  }

  get project(){
    return this.listOfProjects[0]
  }

  private colorByStatus({status}: Project) {
    return Status.getValue(status)
  }

  private editProject(selectedProject: Project) {
    this.createOrUpdateProject = selectedProject
    this.isUpdatingProject = true
    this.createOrUpdate = true
    this.isUpdatingProject = true
  }

  private askForDeleting(selectedProject: Project){
    this.reallyDeleteProject = true;
    this.selectedProjectDetails = selectedProject
  }

  private deleteProject(project: Project){
    this.reallyDeleteProject = false;
    this.$store.dispatch('delteProject', project).then(() => {
      this.refreshProjects()
    })
  }

  private toggleAndRefresh() {
    console.log('createOrUpdate ', this.createOrUpdate)
    this.createOrUpdate = !this.createOrUpdate
    this.isUpdatingProject = false
    this.createOrUpdate = false
    this.isUpdatingProject = false
    this.refreshProjects()
  }

  private loadAllProjects(){

    this.$store.dispatch('getAllProjects').then(projects => {
      this.listOfProjects = projects
      this.isAllProjectLoaded = true
    })
  }

  private refreshProjects() {
    console.log('refreshProjects')
    this.$store.dispatch('getAllUnfinishedProjects').then(projects => {
      this.listOfProjects = projects
    })
  }
}
